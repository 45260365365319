/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/moment@2.27.0/moment.min.js
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/bootstrap@4.5.0/dist/js/bootstrap.min.js
 * - /npm/@editorjs/editorjs@2.17.0/dist/editor.min.js
 * - /npm/@editorjs/quote@2.3.0/dist/bundle.min.js
 * - /npm/@editorjs/header@2.4.1/dist/bundle.min.js
 * - /npm/@editorjs/table@1.2.2/dist/bundle.min.js
 * - /npm/@editorjs/paragraph@2.6.1/dist/bundle.min.js
 * - /npm/@editorjs/embed@2.3.1/dist/bundle.min.js
 * - /npm/@editorjs/image@2.3.4/dist/bundle.min.js
 * - /npm/@editorjs/list@1.4.0/dist/bundle.min.js
 * - /npm/@editorjs/link@2.1.3/dist/bundle.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 * - /npm/bootbox@5.4.0/bootbox.all.min.js
 * - /npm/bootbox@5.4.0/bootbox.locales.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
